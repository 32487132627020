import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/vlad-tutunea-b3246291/"
      }}>{`Hi`}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://github.com/vladovidiu"
      }}>{`Github`}</a>{` and `}<a parentName="p" {...{
        "href": "https://blog.vladtutunea.com"
      }}>{`blog`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      