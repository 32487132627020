import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Experience`}</h2>
    <ProjectCard title="HomeserveNow" link="https://www.homeservenow.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Mar 2020 - Tech Lead
    </ProjectCard>
    <ProjectCard title="Hostmaker" link="https://www.hostmaker.com" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Jul 2018 - Feb 2020 - Tech Lead
    </ProjectCard>
    <ProjectCard title="Kite Eyewear" link="https://kiteeyewear.com/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Sep 2017 - Jul 2018 - Full Stack Developer
    </ProjectCard>
    <ProjectCard title="IMG Media" link="https://www.img.com/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Mar 2016 - Sep 2017 - Senior Front End Engineer
    </ProjectCard>
    <ProjectCard title="Livestream" link="https://livestream.com/" bg="linear-gradient(to right,#1c0fba 0%,#21FCB4 100%)" mdxType="ProjectCard">
  Aug 2015 - Mar 2016 - Front End Engineer
    </ProjectCard>
    <ProjectCard title="Misys" link="https://www.finastra.com/" bg="linear-gradient(to right,#e12dab 0%,#5C27ED 100%)" mdxType="ProjectCard">
  Feb 2013 - Aug 2015 - Software Engineer
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      