import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"Every day, work to refine the skills you have and to add new tools to your repertoire." Andrew Hunt`}</p>
    </blockquote>
    <p>{`Currently I work as Tech Lead for HomeserveNow; my day to day consists of planning product roadmap, mentoring and managing a bunch of great developers, coming up with clean scalable architecture and having fun in the process.
Knowledge includes:`}</p>
    <ul>
      <li parentName="ul">{`Backend with NodeJS/Go`}</li>
      <li parentName="ul">{`Databases - MySQL/MongoDB/DynamoDB`}</li>
      <li parentName="ul">{`FrontEnd with vanilla JavaScript/React`}</li>
      <li parentName="ul">{`DevOps - Docker/Kubernetes/Serverless `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      